/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'

// Style Tokens
//---------------------------------
import tokenData from '../../../assets/design_tokens/heliguy-2024-designTokens-transformed.json'
import { brandGrey, brandOrange } from '../../../assets/consts/color'

const options = {
  renderMark: {
    // [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    // [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,

    // Images and emebeded assets
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (!node.data.target) return null

      if (node.data.target.file && node.data.target.file.url.includes('videos.ctfassets')) {
        return (
          <BlogOuterWrapper>
            <BlogVideoWrapper>
              <video controls>
                <source src={node.data.target.file.url} />
              </video>
            </BlogVideoWrapper>
          </BlogOuterWrapper>
        )
      }

      const { gatsbyImageData, description } = node.data.target
      return (
        <BlogImageWrapper>
          <Zoom zoomMargin={45} classDialog="custom-zoom">
            <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />
          </Zoom>
        </BlogImageWrapper>
      )
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (node.content.some(({ data }) => data.uri && data.uri.includes('youtube.com'))) {
        return <div>{children}</div>
      }

      return <p>{children}</p>
    },
    [BLOCKS.TABLE]: (_, children) => (
      <div className="rich-text-table-wrapper">
        <table>
          <tbody>{children}</tbody>
        </table>
      </div>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      if (node.data.uri.includes('youtube.com')) {
        const match =
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/.exec(uri)
        const videoId = match && match[7].length === 11 ? match[7] : null
        return (
          videoId && (
            <BlogOuterWrapper>
              <BlogVideoWrapper>
                <iframe
                  className="video"
                  title={`https://youtube.com/embed/${videoId}`}
                  src={`https://youtube.com/embed/${videoId}`}
                  allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </BlogVideoWrapper>
            </BlogOuterWrapper>
          )
        )
      }

      return <a href={uri[uri.length - 1] === '/' ? uri : `${uri}/`}>{children}</a>
    },
  },
}

export const BlogImageWrapper = styled.div`
  margin: 80px 0 80px 0;
  border-radius: 8px;
  overflow: hidden;
`

const BlogOuterWrapper = styled.div``

const BlogVideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin: 80px 0 80px 0;
  border-radius: 8px;
  overflow: hidden;

  & .video,
  & video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

// Display
// Bold---------------------------------
const display_fontFamily__bold = tokenData.typography.Display.Bold.value.fontFamily
const display_fontSize__bold = '56'
const display_fontWeight__bold = tokenData.typography.Display.Bold.value.fontWeight
const display_letterSpacing__bold = tokenData.typography.Display.Bold.value.letterSpacing
const display_lineHeight__bold = tokenData.typography.Display.Bold.value.lineHeight
const display_paragraphSpacing__bold = tokenData.typography.Display.Bold.value.paragraphSpacing

// Light---------------------------------
const display_fontFamily__light = tokenData.typography.Display.Light.value.fontFamily
const display_fontSize__light = '56'
const display_fontWeight__light = tokenData.typography.Display.Light.value.fontWeight
const display_letterSpacing__light = tokenData.typography.Display.Light.value.letterSpacing
const display_lineHeight__light = tokenData.typography.Display.Light.value.lineHeight
const display_paragraphSpacing__light = tokenData.typography.Display.Light.value.paragraphSpacing

// Thin---------------------------------
const display_fontFamily__thin = tokenData.typography.Display.Thin.value.fontFamily
const display_fontSize__thin = '56'
const display_fontWeight__thin = tokenData.typography.Display.Thin.value.fontWeight
const display_letterSpacing__thin = tokenData.typography.Display.Thin.value.letterSpacing
const display_lineHeight__thin = tokenData.typography.Display.Thin.value.lineHeight
const display_paragraphSpacing__thin = tokenData.typography.Display.Thin.value.paragraphSpacing

// H1
// Bold---------------------------------
const h1_fontFamily__bold = tokenData.typography.H1.Bold.value.fontFamily
const h1_fontSize__bold = '56'
const h1_fontWeight__bold = tokenData.typography.H1.Bold.value.fontWeight
const h1_letterSpacing__bold = tokenData.typography.H1.Bold.value.letterSpacing
const h1_lineHeight__bold = tokenData.typography.H1.Bold.value.lineHeight
const h1_paragraphSpacing__bold = tokenData.typography.H1.Bold.value.paragraphSpacing

const h1_fontFamily__light = tokenData.typography.H1.Light.value.fontFamily
const h1_fontSize__light = '56'
const h1_fontWeight__light = tokenData.typography.H1.Light.value.fontWeight
const h1_letterSpacing__light = tokenData.typography.H1.Light.value.letterSpacing
const h1_lineHeight__light = tokenData.typography.H1.Light.value.lineHeight
const h1_paragraphSpacing__light = tokenData.typography.H1.Light.value.paragraphSpacing

const h1_fontFamily__thin = tokenData.typography.H1.Thin.value.fontFamily
const h1_fontSize__thin = '56'
const h1_fontWeight__thin = tokenData.typography.H1.Thin.value.fontWeight
const h1_letterSpacing__thin = tokenData.typography.H1.Thin.value.letterSpacing
const h1_lineHeight__thin = tokenData.typography.H1.Thin.value.lineHeight
const h1_paragraphSpacing__thin = tokenData.typography.H1.Thin.value.paragraphSpacing

// H2
// Bold---------------------------------
const h2_fontFamily__bold = tokenData.typography.H2.Bold.value.fontFamily
const h2_fontSize__bold = '39'
const h2_fontWeight__bold = tokenData.typography.H2.Bold.value.fontWeight
const h2_letterSpacing__bold = tokenData.typography.H2.Bold.value.letterSpacing
const h2_lineHeight__bold = tokenData.typography.H2.Bold.value.lineHeight
const h2_paragraphSpacing__bold = tokenData.typography.H2.Bold.value.paragraphSpacing

const h2_fontFamily__light = tokenData.typography.H2.Light.value.fontFamily
const h2_fontSize__light = '39'
const h2_fontWeight__light = tokenData.typography.H2.Light.value.fontWeight
const h2_letterSpacing__light = tokenData.typography.H2.Light.value.letterSpacing
const h2_lineHeight__light = tokenData.typography.H2.Light.value.lineHeight
const h2_paragraphSpacing__light = tokenData.typography.H2.Light.value.paragraphSpacing

const h2_fontFamily__thin = tokenData.typography.H2.Thin.value.fontFamily
const h2_fontSize__thin = '39'
const h2_fontWeight__thin = tokenData.typography.H2.Thin.value.fontWeight
const h2_letterSpacing__thin = tokenData.typography.H2.Thin.value.letterSpacing
const h2_lineHeight__thin = tokenData.typography.H2.Thin.value.lineHeight
const h2_paragraphSpacing__thin = tokenData.typography.H2.Thin.value.paragraphSpacing

// H3
// Bold---------------------------------
const h3_fontFamily__bold = tokenData.typography.H3.Bold.value.fontFamily
const h3_fontSize__bold = '28'
const h3_fontWeight__bold = tokenData.typography.H3.Bold.value.fontWeight
const h3_letterSpacing__bold = tokenData.typography.H3.Bold.value.letterSpacing
const h3_lineHeight__bold = tokenData.typography.H3.Bold.value.lineHeight
const h3_paragraphSpacing__bold = tokenData.typography.H3.Bold.value.paragraphSpacing

const h3_fontFamily__light = tokenData.typography.H3.Light.value.fontFamily
const h3_fontSize__light = '28'
const h3_fontWeight__light = tokenData.typography.H3.Light.value.fontWeight
const h3_letterSpacing__light = tokenData.typography.H3.Light.value.letterSpacing
const h3_lineHeight__light = tokenData.typography.H3.Light.value.lineHeight
const h3_paragraphSpacing__light = tokenData.typography.H3.Light.value.paragraphSpacing

const h3_fontFamily__thin = tokenData.typography.H3.Thin.value.fontFamily
const h3_fontSize__thin = '28'
const h3_fontWeight__thin = tokenData.typography.H3.Thin.value.fontWeight
const h3_letterSpacing__thin = tokenData.typography.H3.Thin.value.letterSpacing
const h3_lineHeight__thin = tokenData.typography.H3.Thin.value.lineHeight
const h3_paragraphSpacing__thin = tokenData.typography.H3.Thin.value.paragraphSpacing

// H4
// Bold---------------------------------
const h4_fontFamily__bold = tokenData.typography.H4.Bold.value.fontFamily
const h4_fontSize__bold = '20'
const h4_fontWeight__bold = tokenData.typography.H4.Bold.value.fontWeight
const h4_letterSpacing__bold = tokenData.typography.H4.Bold.value.letterSpacing
const h4_lineHeight__bold = tokenData.typography.H4.Bold.value.lineHeight
const h4_paragraphSpacing__bold = tokenData.typography.H4.Bold.value.paragraphSpacing

const h4_fontFamily__light = tokenData.typography.H4.Light.value.fontFamily
const h4_fontSize__light = '20'
const h4_fontWeight__light = tokenData.typography.H4.Light.value.fontWeight
const h4_letterSpacing__light = tokenData.typography.H4.Light.value.letterSpacing
const h4_lineHeight__light = tokenData.typography.H4.Light.value.lineHeight
const h4_paragraphSpacing__light = tokenData.typography.H4.Light.value.paragraphSpacing

const h4_fontFamily__thin = tokenData.typography.H4.Thin.value.fontFamily
const h4_fontSize__thin = '20'
const h4_fontWeight__thin = tokenData.typography.H4.Thin.value.fontWeight
const h4_letterSpacing__thin = tokenData.typography.H4.Thin.value.letterSpacing
const h4_lineHeight__thin = tokenData.typography.H4.Thin.value.lineHeight
const h4_paragraphSpacing__thin = tokenData.typography.H4.Thin.value.paragraphSpacing

// H5
// Bold---------------------------------
const h5_fontFamily__bold = tokenData.typography.H5.Bold.value.fontFamily
const h5_fontSize__bold = '16'
const h5_fontWeight__bold = tokenData.typography.H5.Bold.value.fontWeight
const h5_letterSpacing__bold = tokenData.typography.H5.Bold.value.letterSpacing
const h5_lineHeight__bold = tokenData.typography.H5.Bold.value.lineHeight
const h5_paragraphSpacing__bold = tokenData.typography.H5.Bold.value.paragraphSpacing

const h5_fontFamily__light = tokenData.typography.H5.Light.value.fontFamily
const h5_fontSize__light = '16'
const h5_fontWeight__light = tokenData.typography.H5.Light.value.fontWeight
const h5_letterSpacing__light = tokenData.typography.H5.Light.value.letterSpacing
const h5_lineHeight__light = tokenData.typography.H5.Light.value.lineHeight
const h5_paragraphSpacing__light = tokenData.typography.H5.Light.value.paragraphSpacing

const h5_fontFamily__thin = tokenData.typography.H5.Thin.value.fontFamily
const h5_fontSize__thin = '16'
const h5_fontWeight__thin = tokenData.typography.H5.Thin.value.fontWeight
const h5_letterSpacing__thin = tokenData.typography.H5.Thin.value.letterSpacing
const h5_lineHeight__thin = tokenData.typography.H5.Thin.value.lineHeight
const h5_paragraphSpacing__thin = tokenData.typography.H5.Thin.value.paragraphSpacing

// P
// Bold---------------------------------
const p_fontFamily__bold = tokenData.typography.body.bold.value.fontFamily
const p_fontSize__bold = '16'
const p_fontWeight__bold = tokenData.typography.body.bold.value.fontWeight
const p_letterSpacing__bold = tokenData.typography.body.bold.value.letterSpacing
const p_lineHeight__bold = tokenData.typography.body.bold.value.lineHeight
const p_paragraphSpacing__bold = tokenData.typography.body.bold.value.paragraphSpacing

const p_fontFamily__regular = tokenData.typography.body.regular.value.fontFamily
const p_fontSize__regular = '16'
const p_fontWeight__regular = tokenData.typography.body.regular.value.fontWeight
const p_letterSpacing__regular = tokenData.typography.body.regular.value.letterSpacing
const p_lineHeight__regular = tokenData.typography.body.regular.value.lineHeight
const p_paragraphSpacing__regular = tokenData.typography.body.regular.value.paragraphSpacing

// Styled Component Declarations
//---------------------------------

// Display Bold
//---------------------------------
const DisplayBold = styled.h1`
  font-family: ${display_fontFamily__bold};
  font-size: 32px;
  font-weight: ${display_fontWeight__bold};
  letter-spacing: ${display_letterSpacing__bold}px;
  line-height: ${display_lineHeight__bold};
  margin-bottom: ${display_paragraphSpacing__bold}px;

  @media only screen and (min-width: 48em) {
    font-family: ${display_fontFamily__bold};
    font-size: ${display_fontSize__bold}px;
    font-weight: ${display_fontWeight__bold};
    letter-spacing: ${display_letterSpacing__bold}px;
    line-height: ${display_lineHeight__bold};
    margin-bottom: ${display_paragraphSpacing__bold}px;
  }
`

const DisplayLight = styled.h1`
  font-family: ${display_fontFamily__light};
  font-size: 32px;
  font-weight: ${display_fontWeight__light};
  letter-spacing: ${display_letterSpacing__light}px;
  line-height: ${display_lineHeight__light};
  margin-bottom: ${display_paragraphSpacing__light}px;

  @media only screen and (min-width: 48em) {
    font-family: ${display_fontFamily__light};
    font-size: ${display_fontSize__light}px;
    font-weight: ${display_fontWeight__light};
    letter-spacing: ${display_letterSpacing__light}px;
    line-height: ${display_lineHeight__light};
    margin-bottom: ${display_paragraphSpacing__light}px;
  }
`

const DisplayThin = styled.h1`
  font-family: ${display_fontFamily__thin};
  font-size: 32px;
  font-weight: ${display_fontWeight__thin};
  letter-spacing: ${display_letterSpacing__thin}px;
  line-height: ${display_lineHeight__thin};
  margin-bottom: ${display_paragraphSpacing__thin}px;

  @media only screen and (min-width: 48em) {
    font-family: ${display_fontFamily__thin};
    font-size: ${display_fontSize__thin}px;
    font-weight: ${display_fontWeight__thin};
    letter-spacing: ${display_letterSpacing__thin}px;
    line-height: ${display_lineHeight__thin};
    margin-bottom: ${display_paragraphSpacing__thin}px;
  }
`

// H1 Bold
//---------------------------------
const H1Bold = styled.h1`
  font-family: ${h1_fontFamily__bold};
  font-size: 29px;
  font-weight: ${h1_fontWeight__bold};
  letter-spacing: ${h1_letterSpacing__bold}px;
  line-height: ${h1_lineHeight__bold};
  margin-bottom: ${h1_paragraphSpacing__bold}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h1_fontFamily__bold};
    font-size: ${h1_fontSize__bold}px;
    font-weight: ${h1_fontWeight__bold};
    letter-spacing: ${h1_letterSpacing__bold}px;
    line-height: ${h1_lineHeight__bold};
    margin-bottom: ${h1_paragraphSpacing__bold}px;
  }
`

const H1Light = styled.h1`
  font-family: ${h1_fontFamily__light};
  font-size: 29px;
  font-weight: ${h1_fontWeight__light};
  letter-spacing: ${h1_letterSpacing__light}px;
  line-height: ${h1_lineHeight__light};
  margin-bottom: ${h1_paragraphSpacing__light}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h1_fontFamily__light};
    font-size: ${h1_fontSize__light}px;
    font-weight: ${h1_fontWeight__light};
    letter-spacing: ${h1_letterSpacing__light}px;
    line-height: ${h1_lineHeight__light};
    margin-bottom: ${h1_paragraphSpacing__light}px;
  }
`

const H1Thin = styled.h1`
  font-family: ${h1_fontFamily__thin};
  font-size: 29px;
  font-weight: ${h1_fontWeight__thin};
  letter-spacing: ${h1_letterSpacing__thin}px;
  line-height: ${h1_lineHeight__thin};
  margin-bottom: ${h1_paragraphSpacing__thin}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h1_fontFamily__thin};
    font-size: ${h1_fontSize__thin}px;
    font-weight: ${h1_fontWeight__thin};
    letter-spacing: ${h1_letterSpacing__thin}px;
    line-height: ${h1_lineHeight__thin};
    margin-bottom: ${h1_paragraphSpacing__thin}px;
  }
`

// H2 Bold
//---------------------------------
const H2Bold = styled.h2`
  font-family: ${h2_fontFamily__bold};
  font-size: 26px;
  font-weight: ${h2_fontWeight__bold};
  letter-spacing: ${h2_letterSpacing__bold}px;
  line-height: ${h2_lineHeight__bold};
  margin-bottom: ${h2_paragraphSpacing__bold}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h2_fontFamily__bold};
    font-size: ${h2_fontSize__bold}px;
    font-weight: ${h2_fontWeight__bold};
    letter-spacing: ${h2_letterSpacing__bold}px;
    line-height: ${h2_lineHeight__bold};
    margin-bottom: ${h2_paragraphSpacing__bold}px;
  }
`

const H2Light = styled.h2`
  font-family: ${h2_fontFamily__light};
  font-size: 26px;
  font-weight: ${h2_fontWeight__light};
  letter-spacing: ${h2_letterSpacing__light}px;
  line-height: ${h2_lineHeight__light};
  margin-bottom: ${h2_paragraphSpacing__light}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h2_fontFamily__light};
    font-size: ${h2_fontSize__light}px;
    font-weight: ${h2_fontWeight__light};
    letter-spacing: ${h2_letterSpacing__light}px;
    line-height: ${h2_lineHeight__light};
    margin-bottom: ${h2_paragraphSpacing__light}px;
  }
`

const H2Thin = styled.h2`
  font-family: ${h2_fontFamily__thin};
  font-size: 26px;
  font-weight: ${h2_fontWeight__thin};
  letter-spacing: ${h2_letterSpacing__thin}px;
  line-height: ${h2_lineHeight__thin};
  margin-bottom: ${h2_paragraphSpacing__thin}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h2_fontFamily__thin};
    font-size: ${h2_fontSize__thin}px;
    font-weight: ${h2_fontWeight__thin};
    letter-spacing: ${h2_letterSpacing__thin}px;
    line-height: ${h2_lineHeight__thin};
    margin-bottom: ${h2_paragraphSpacing__thin}px;
  }
`

// H3 Bold
//---------------------------------
const H3Bold = styled.h3`
  font-family: ${h3_fontFamily__bold};
  font-size: 23px;
  font-weight: ${h3_fontWeight__bold};
  letter-spacing: ${h3_letterSpacing__bold}px;
  line-height: ${h3_lineHeight__bold};
  margin-bottom: ${h3_paragraphSpacing__bold}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h3_fontFamily__bold};
    font-size: ${h3_fontSize__bold}px;
    font-weight: ${h3_fontWeight__bold};
    letter-spacing: ${h3_letterSpacing__bold}px;
    line-height: ${h3_lineHeight__bold};
    margin-bottom: ${h3_paragraphSpacing__bold}px;
  }
`

const H3Light = styled.h3`
  font-family: ${h3_fontFamily__light};
  font-size: 23px;
  font-weight: ${h3_fontWeight__light};
  letter-spacing: ${h3_letterSpacing__light}px;
  line-height: ${h3_lineHeight__light};
  margin-bottom: ${h3_paragraphSpacing__light}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h3_fontFamily__light};
    font-size: ${h3_fontSize__light}px;
    font-weight: ${h3_fontWeight__light};
    letter-spacing: ${h3_letterSpacing__light}px;
    line-height: ${h3_lineHeight__light};
    margin-bottom: ${h3_paragraphSpacing__light}px;
  }
`

const H3Thin = styled.h3`
  font-family: ${h3_fontFamily__thin};
  font-size: 23px;
  font-weight: ${h3_fontWeight__thin};
  letter-spacing: ${h3_letterSpacing__thin}px;
  line-height: ${h3_lineHeight__thin};
  margin-bottom: ${h3_paragraphSpacing__thin}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h3_fontFamily__thin};
    font-size: ${h3_fontSize__thin}px;
    font-weight: ${h3_fontWeight__thin};
    letter-spacing: ${h3_letterSpacing__thin}px;
    line-height: ${h3_lineHeight__thin};
    margin-bottom: ${h3_paragraphSpacing__thin}px;
  }
`

// H4 Bold
//---------------------------------
const H4Bold = styled.h4`
  font-family: ${h4_fontFamily__bold};
  font-size: 20px;
  font-weight: ${h4_fontWeight__bold};
  letter-spacing: ${h4_letterSpacing__bold}px;
  line-height: ${h4_lineHeight__bold};
  margin-bottom: ${h4_paragraphSpacing__bold}px;
  margin-top: 0;

  @media only screen and (min-width: 48em) {
    font-family: ${h4_fontFamily__bold};
    font-size: ${h4_fontSize__bold}px;
    font-weight: ${h4_fontWeight__bold};
    letter-spacing: ${h4_letterSpacing__bold}px;
    line-height: ${h4_lineHeight__bold};
    margin-bottom: ${h4_paragraphSpacing__bold}px;
  }
`

const H4Light = styled.h4`
  font-family: ${h4_fontFamily__light};
  font-size: 20px;
  font-weight: ${h4_fontWeight__light};
  letter-spacing: ${h4_letterSpacing__light}px;
  line-height: ${h4_lineHeight__light};
  margin-bottom: ${h4_paragraphSpacing__light}px;
  margin-top: 0;

  @media only screen and (min-width: 48em) {
    font-family: ${h4_fontFamily__light};
    font-size: ${h4_fontSize__light}px;
    font-weight: ${h4_fontWeight__light};
    letter-spacing: ${h4_letterSpacing__light}px;
    line-height: ${h4_lineHeight__light};
    margin-bottom: ${h4_paragraphSpacing__light}px;
  }
`

const H4Thin = styled.h4`
  font-family: ${h4_fontFamily__thin};
  font-size: 20px;
  font-weight: ${h4_fontWeight__thin};
  letter-spacing: ${h4_letterSpacing__thin}px;
  line-height: ${h4_lineHeight__thin};
  margin-bottom: ${h4_paragraphSpacing__thin}px;
  margin-top: 0;

  @media only screen and (min-width: 48em) {
    font-family: ${h4_fontFamily__thin};
    font-size: ${h4_fontSize__thin}px;
    font-weight: ${h4_fontWeight__thin};
    letter-spacing: ${h4_letterSpacing__thin}px;
    line-height: ${h4_lineHeight__thin};
    margin-bottom: ${h4_paragraphSpacing__thin}px;
  }
`

// H5 Bold
//---------------------------------
const H5Bold = styled.h5`
  font-family: ${h5_fontFamily__bold};
  font-size: 18px;
  font-weight: ${h5_fontWeight__bold};
  letter-spacing: ${h5_letterSpacing__bold}px;
  line-height: ${h5_lineHeight__bold};
  margin-bottom: ${h5_paragraphSpacing__bold}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h5_fontFamily__bold};
    font-size: ${h5_fontSize__bold}px;
    font-weight: ${h5_fontWeight__bold};
    letter-spacing: ${h5_letterSpacing__bold}px;
    line-height: ${h5_lineHeight__bold};
    margin-bottom: ${h5_paragraphSpacing__bold}px;
  }
`

const H5Light = styled.h5`
  font-family: ${h5_fontFamily__light};
  font-size: 18px;
  font-weight: ${h5_fontWeight__light};
  letter-spacing: ${h5_letterSpacing__light}px;
  line-height: ${h5_lineHeight__light};
  margin-bottom: ${h5_paragraphSpacing__light}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h5_fontFamily__light};
    font-size: ${h5_fontSize__light}px;
    font-weight: ${h5_fontWeight__light};
    letter-spacing: ${h5_letterSpacing__light}px;
    line-height: ${h5_lineHeight__light};
    margin-bottom: ${h5_paragraphSpacing__light}px;
  }
`

const H5Thin = styled.h5`
  font-family: ${h5_fontFamily__thin};
  font-size: 18px;
  font-weight: ${h5_fontWeight__thin};
  letter-spacing: ${h5_letterSpacing__thin}px;
  line-height: ${h5_lineHeight__thin};
  margin-bottom: ${h5_paragraphSpacing__thin}px;

  @media only screen and (min-width: 48em) {
    font-family: ${h5_fontFamily__thin};
    font-size: ${h5_fontSize__thin}px;
    font-weight: ${h5_fontWeight__thin};
    letter-spacing: ${h5_letterSpacing__thin}px;
    line-height: ${h5_lineHeight__thin};
    margin-bottom: ${h5_paragraphSpacing__thin}px;
  }
`

// Paragraph Bold
//---------------------------------
const PBold = styled.p`
  font-family: ${p_fontFamily__bold};
  font-size: 14px;
  font-weight: ${p_fontWeight__bold};
  letter-spacing: ${p_letterSpacing__bold}px;
  line-height: ${p_lineHeight__bold};
  margin-top: ${p_paragraphSpacing__bold}px;
  margin-bottom: ${p_paragraphSpacing__bold}px;

  @media only screen and (min-width: 48em) {
    font-family: ${p_fontFamily__bold};
    font-size: ${p_fontSize__bold}px;
    font-weight: ${p_fontWeight__bold};
    letter-spacing: ${p_letterSpacing__bold}px;
    line-height: ${p_lineHeight__bold};
    margin-top: ${p_paragraphSpacing__bold}px;
    margin-bottom: ${p_paragraphSpacing__bold}px;
  }
`

const PLight = styled.p`
  font-family: ${p_fontFamily__regular};
  font-size: 14px;
  font-weight: ${p_fontWeight__regular};
  letter-spacing: ${p_letterSpacing__regular}px;
  line-height: ${p_lineHeight__regular};
  margin-top: ${p_paragraphSpacing__regular}px;
  margin-bottom: ${p_paragraphSpacing__regular}px;

  @media only screen and (min-width: 48em) {
    font-family: ${p_fontFamily__regular};
    font-size: ${p_fontSize__regular}px;
    font-weight: ${p_fontWeight__regular};
    letter-spacing: ${p_letterSpacing__regular}px;
    line-height: ${p_lineHeight__regular};
    margin-top: ${p_paragraphSpacing__regular}px;
    margin-bottom: ${p_paragraphSpacing__regular}px;
  }
`

const PGrey = styled(PLight)`
  color: ${brandGrey};
`

const POrange = styled(PLight)`
  color: ${brandOrange};
`

const BlockText = styled.div`
  font-family: ${p_fontFamily__regular};
  font-size: 14px;
  font-weight: ${p_fontWeight__regular};
  letter-spacing: ${p_letterSpacing__regular}px;
  line-height: ${p_lineHeight__regular};
  margin-top: ${p_paragraphSpacing__regular}px;
  margin-bottom: ${p_paragraphSpacing__regular}px;

  @media only screen and (min-width: 48em) {
    font-family: ${p_fontFamily__regular};
    font-size: ${p_fontSize__regular}px;
    font-weight: ${p_fontWeight__regular};
    letter-spacing: ${p_letterSpacing__regular}px;
    line-height: ${p_lineHeight__regular};
    margin-top: ${p_paragraphSpacing__regular}px;
    margin-bottom: ${p_paragraphSpacing__regular}px;
  }
  ul {
    list-style-type: initial;
  }

  p:first-of-type {
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }
`

const BlogText = styled(BlockText)`
  ${BlogImageWrapper} {
    @media only screen and (min-width: 90em) {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  ${BlogOuterWrapper} {
    @media only screen and (min-width: 90em) {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const EmptyState = styled(H2Thin)`
  text-align: center;
`

const BlockTextReview = styled(BlockText)`
  p {
    font-family: ${h5_fontFamily__thin};
    font-size: 16px;
    margin: 0;

    @media only screen and (min-width: 48em) {
      font-size: 20px;
    }
  }

  margin-bottom: 24px;

  @media only screen and (min-width: 48em) {
    margin-bottom: 40px;
  }
`

const ReviewSign = styled(PLight)`
  font-family: ${h5_fontFamily__thin};
`

// Component Export
//---------------------------------
export default function TypographyAtom({
  className,
  weight = 'Bold',
  type = 'H1',
  copy = 'This is a line of copy',
  color,
  copyBlock = 'This is a copy block atom',
}) {
  return (
    <>
      {type === 'Display' && weight === 'Bold' && (
        <DisplayBold className={className}>{copy}</DisplayBold>
      )}
      {type === 'H1' && weight === 'Bold' && <H1Bold className={className}>{copy}</H1Bold>}
      {type === 'H2' && weight === 'Bold' && <H2Bold className={className}>{copy}</H2Bold>}
      {type === 'H3' && weight === 'Bold' && <H3Bold className={className}>{copy}</H3Bold>}
      {type === 'H4' && weight === 'Bold' && <H4Bold className={className}>{copy}</H4Bold>}
      {type === 'H5' && weight === 'Bold' && <H5Bold className={className}>{copy}</H5Bold>}

      {type === 'Display' && weight === 'Thin' && (
        <DisplayThin className={className}>{copy}</DisplayThin>
      )}
      {type === 'H1' && weight === 'Thin' && <H1Thin className={className}>{copy}</H1Thin>}
      {type === 'H2' && weight === 'Thin' && <H2Thin className={className}>{copy}</H2Thin>}
      {type === 'H3' && weight === 'Thin' && <H3Thin className={className}>{copy}</H3Thin>}
      {type === 'H4' && weight === 'Thin' && <H4Thin className={className}>{copy}</H4Thin>}
      {type === 'H5' && weight === 'Thin' && <H5Thin className={className}>{copy}</H5Thin>}

      {type === 'Display' && weight === 'Light' && (
        <DisplayLight className={className}>{copy}</DisplayLight>
      )}
      {type === 'H1' && weight === 'Light' && <H1Light className={className}>{copy}</H1Light>}
      {type === 'H2' && weight === 'Light' && <H2Light className={className}>{copy}</H2Light>}
      {type === 'H3' && weight === 'Light' && <H3Light className={className}>{copy}</H3Light>}
      {type === 'H4' && weight === 'Light' && <H4Light className={className}>{copy}</H4Light>}
      {type === 'H5' && weight === 'Light' && <H5Light className={className}>{copy}</H5Light>}

      {type === 'P' && weight === 'Bold' && <PBold className={className}>{copy}</PBold>}
      {type === 'P' && weight === 'Regular' && !color && (
        <PLight className={className}>{copy}</PLight>
      )}
      {type === 'P' && weight === 'Regular' && color === 'Grey' && (
        <PGrey className={className}>{copy}</PGrey>
      )}
      {type === 'P' && weight === 'Regular' && color === 'Orange' && (
        <POrange className={className}>{copy}</POrange>
      )}
      {type === 'ReviewSign' && weight === 'Regular' && (
        <ReviewSign className={className}>{copy}</ReviewSign>
      )}
      {type === 'BlockText' && weight === 'Regular' && (
        <PLight className={className}>
          {copyBlock.raw
            ? renderRichText(copyBlock, options)
            : documentToReactComponents(copyBlock, options)}
        </PLight>
      )}
      {type === 'BlockText' && weight === 'BlockText' && (
        <BlockText className={className}>
          {copyBlock.raw
            ? renderRichText(copyBlock, options)
            : documentToReactComponents(copyBlock, options)}
        </BlockText>
      )}
      {type === 'BlogText' && weight === 'BlockText' && (
        <BlogText className={className}>
          {copyBlock.raw
            ? renderRichText(copyBlock, options)
            : documentToReactComponents(copyBlock, options)}
        </BlogText>
      )}
      {type === 'BlockTextReview' && weight === 'Regular' && (
        <BlockTextReview className={className}>
          {copyBlock.raw
            ? renderRichText(copyBlock, options)
            : documentToReactComponents(copyBlock, options)}
        </BlockTextReview>
      )}
      {type === 'Empty State' && weight === 'Regular' && <EmptyState as="p">{copy}</EmptyState>}
    </>
  )
}

TypographyAtom.propTypes = {
  type: PropTypes.string,
  copy: PropTypes.string,
  copyBlock: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  weight: PropTypes.string,
}
